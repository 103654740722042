body {
    margin: 0;
    background-color: #1f222c;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.navsidebar {
}

.navsidebar .MuiList-root .MuiListItem-root.Mui-selected {
    background-color: rgb(235, 248, 246);
    border-radius: 100px;
}

.navsidebar .MuiList-root .MuiListItem-root:hover {
    background-color: rgba(7, 105, 91, 0.25);
    border-radius: 100px;
}

.navsidebar .MuiList-root .MuiListItem-root .MuiListItemText-root {
    padding-left: 25px;
}

.navsidebar .MuiList-root .MuiListItem-root .MuiListItemIcon-root {
    padding-left: 7px;
}

.navsidebar .MuiList-root .MuiListItem-root .MuiListItemIcon-root {
    color: #000000 !important;
}

.navsidebar .MuiList-root .MuiListItem-root .MuiListItemText-root .MuiTypography-root {
    font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
        Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 18px;
}

.navsidebar .MuiList-root .MuiListItem-root.Mui-selected .MuiListItemText-root .MuiTypography-root {
    font-weight: 400;
}

.navsidebar .MuiList-root .MuiListItemButton-root {
    border-radius: 100px;
}

.navsidebar .MuiList-root .MuiListItemButton-root.Mui-selected {
    background-color: rgb(235, 248, 246);
    border-radius: 100px;
}

.navsidebar .MuiList-root .MuiListItemButton-root:hover {
    background-color: rgba(7, 105, 91, 0.25);
    border-radius: 100px;
}

.navsidebar .MuiList-root .MuiListItemButton-root .MuiListItemText-root {
    padding-left: 7px;
}

.navsidebar .MuiList-root .MuiListItemButton-root .MuiListItemIcon-root {
    padding-left: 7px;
}

.navsidebar .MuiList-root .MuiListItemButton-root .MuiListItemIcon-root {
    color: #000000 !important;
}

.navsidebar .MuiList-root .MuiListItemButton-root .MuiListItemText-root .MuiTypography-root {
    font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
        Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px !important;
    font-weight: 400;
    line-height: 18px;
}

.navsidebar
    .MuiList-root
    .MuiListItemButton-root.Mui-selected
    .MuiListItemText-root
    .MuiTypography-root {
    font-weight: 400;
}

.toolbar {
    color: #1f222c;
    background-color: white;
}

.not-active-content {
    pointer-events: none;
    cursor: default;
}

.fc-daygrid-block-event .fc-event-time,
.fc-daygrid-block-event .fc-event-title {
    padding: 5px;
    white-space: normal;
}

.fc-daygrid-block-event:hover {
    cursor: pointer;
}

.fc-toolbar-title {
    font-size: 14px !important;
    font-weight: 500;
}

.fc-header-toolbar .fc-button-primary,
.fc-header-toolbar .fc-button-active,
.fc-header-toolbar .fc-button {
    background-color: white !important;
    border-width: 0px !important;
    border-radius: 100px !important;
    font-size: 12px !important;
    font-family: "Noto Sans", Arial, Roboto, "Helvetica Neue", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
    color: #307e92 !important;
    padding: 7px;
    height: 34px !important;
}

.fc-header-toolbar .fc-button:disabled {
    background-color: rgb(255, 255, 255) !important;
    border-color: rgb(166, 166, 166) !important;
    color: #307e92 !important;
}

.fc-header-toolbar .fc-button:hover {
    background-color: #eaeff4 !important;
    border-radius: 100px !important;
}

.fc-header-toolbar .fc-button:focus {
    background-color: #eaeff4 !important;
    border-radius: 100px !important;
}

.fc-button-primary:focus {
    box-shadow: none !important;
}

.fc-col-header-cell a {
    font-size: 13px !important;
    font-weight: 400;
    font-family: "Noto Sans", Arial, Roboto, "Helvetica Neue", sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol";
}

.fc .fc-daygrid-day.fc-day-today {
    background-color: rgb(235, 248, 246);
}
